<template>
  <base-layout-two
    page-title="Affiliate"
    page-default-back-link="/affiliates"
    end-button-text="Edit"
    :end-button-url="`/affiliates/${$route.params.id}/edit`"
  >
    <section class="ion-padding background-white">
      <h1>{{ affiliate.name }}</h1>
    </section>

    <div class="padding-8">
      <base-card title="Affiliate Details">
      <base-field-display label-text="Code">
        <p>{{ affiliate.code }}</p>
      </base-field-display>

      <base-field-display label-text="URL">
        <p>
          <a :href="affiliate.url" target="_blank">{{ affiliate.url }}</a>
        </p>
      </base-field-display>

      <base-field-display label-text="Type">
        <p>{{ affiliate.type }}</p>
      </base-field-display>

      <base-field-display label-text="Email">
        <p>{{ affiliate.email }}</p>
      </base-field-display>

      <base-field-display label-text="Number">
        <p>{{ affiliate.number }}</p>
      </base-field-display>
    </base-card>
    </div>
  </base-layout-two>
</template>

<script>
export default {
  data() {
    return {
      affiliate: {},
    };
  },

  ionViewWillEnter() {
    this.fetchAffiliate();
  },

  methods: {
    async fetchAffiliate() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/affiliates/${this.$route.params.id}`)
        .then((response) => {
          this.affiliate = response.data.affiliate;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>